import { API_URL } from "@/_plugins/axios";
import withoutWatchersMixin from "@/_plugins/withoutWatchersMixin";
import debounce from "lodash/debounce";
import Multiselect from "vue-multiselect";
import Form from "@/_plugins/form";
import Pagination from "laravel-vue-pagination";
import HasError from "@/components/HasError";
import i18n from "@/_plugins/i18n";
import DetailsModal from "@/components/DetailsModal";
import File from "../../components/File";
import { mapGetters } from "vuex";
import Vue from "vue";

export default {
  mixins: [withoutWatchersMixin],
  name: "Equipment",
  components: {
    Multiselect,
    Pagination,
    HasError,
    DetailsModal,
    File,
  },
  data() {
    return {
      usersEquipment: [],
      equipmentCategories: [],
      equipmentTypes: [],
      subcategories: [],
      subcategoriesOptions: [],
      equipmentStatuses: [],
      fileRecords: [],
      fileRecordImport: null,
      users: [],
      deleteId: null,
      item: null,
      equipment: [],
      selectedUser: null,
      selectedCategory: null,
      selectedEquipmentType: null,
      selectedEquipment: null,
      selectedSubcategory: null,
      selectedEquipmentStatus: null,
      equipmentRent: [],
      warranty: null,
      purchased_at: null,
      equipmentImages: [],
      form: new Form({
        name: "",
        state: "",
        inventory_number: "",
        serial_number: "",
        files: [],
        // warranty: new Date(),
      }),
      form_import: new Form({
        file: null,
      }),
      delete: new Form({
        id: null,
        // warranty: new Date(),
      }),
      rent_form: new Form({
        user_id: null,
        equipment_id: [],
      }),
      value: null,
      currentPage: 1,
      loading: false,
      loadingExport: false,
      searchDropdown: false,
      deleting: false,
      modalTitle: i18n.tc("equipment.add_new_equipment"),
      filters: {
        term: "",
        category: 0,
        subcategory: 0,
        available: 0,
        inventory_number: "",
        perPage: 10,
      },
      baseurl: process.env.VUE_APP_BASE_BACKEND_URL,
    };
  },
  methods: {
    resetFilters() {
      this.filters.category = 0;
      this.filters.subcategory = 0;
      this.filters.available = 0;
      this.filters.inventory_number = "";
    },
    warrantyChanged() {
      this.form.warranty = this.warranty;
    },
    patChanged() {
      this.form.purchased_at = this.purchased_at;
    },
    rent() {
      this.rent_form
        .post("rent-equipment")
        .then(() => {
          this.getAllEquipment();
          this.$toast.success(i18n.tc("notifications.success"));
          this.$bvModal.hide("modal-2");
        })
        .catch(() => {
          // console.log("ERROR", error.data);
        });
    },
    rentModal(i) {
      this.equipmentRent.push([{ id: i.id, name: i.name }]);
      this.rent_form.equipment_id.push(i.id);
      this.selectedEquipment = { id: i.id, name: i.name };
      this.$bvModal.show("modal-2");
    },
    onFileChange(e) {
      this.form.file = e.target.files[0];
    },
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      validFileRecords.forEach((filee) => {
        this.form.files = this.form.files.concat(filee.file);
      });
    },
    filesSelectedImport: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter(
        (fileRecord) => !fileRecord.error
      );
      validFileRecords.forEach((currFile) => {
        this.form_import.file = currFile.file;
      });
    },
    onBeforeDeleteImport: function () {
      this.fileRecordImport = null;
      this.form_import.file = null;
    },
    onBeforeDelete: function (fileRecord) {
      var i = this.form.files.indexOf(fileRecord);
      // queued file, not yet uploaded. Just remove from the arrays
      this.form.files.splice(i, 1);
      var k = this.fileRecords.indexOf(fileRecord);
      if (k !== -1) this.fileRecords.splice(k, 1);
    },
    fileDeleted: function (fileRecord) {
      var i = this.form.files.indexOf(fileRecord);
      if (i !== -1) {
        this.form.files.splice(i, 1);
      } else {
        this.deleteUploadedFile(fileRecord);
      }
    },
    changeSubcategory(filter = false) {
      if (filter) {
        this.filters.subcategory = 0;
        let subcategories = [];
        this.equipmentCategories.filter((item) => {
          if (item.id === this.filters.category)
            subcategories = item.subcategories;
        }),
          (this.subcategoriesOptions = [
            { id: 0, name: "Sve potkategorije" },
            ...subcategories,
          ]);
      } else {
        this.form.category_id = this.selectedCategory.id;
        let subcategories = [];
        this.equipmentCategories.filter((item) => {
          if (item.id === this.form.category_id) {
            console.log(item);
            subcategories = item.subcategories;
          }
        });
        this.subcategories = [...subcategories];
      }
    },
    details(i) {
      this.item = i;
      this.$bvModal.show("details");
    },
    edit(item) {
      this.modalTitle = i18n.tc("equipment.edit_equipment");
      this.item = item;
      this.warranty = item.warranty;
      this.purchased_at = item.purchased_at;
      this.form.id = item.id;
      this.form.category_id = item.category.id;
      this.form.subcategory_id = item.subcategory.id;
      this.form.equipment_status_id = item.equipment_status.id;
      this.form.category_id = item.category.id;
      this.form.name = item.name;
      this.form.state = item.state;
      this.form.warranty = item.warranty;
      this.form.purchased_at = item.purchased_at;
      this.form.price = item.price;
      this.form.serial_number = item.serial_number;
      this.form.inventory_number = item.inventory_number_append;
      this.selectedCategory = item.category;
      this.selectedSubcategory = item.subcategory;
      this.selectedEquipmentStatus = item.equipment_status;

      this.changeSubcategory(false);
      this.$bvModal.show("modal-1");
    },
    gallery(item) {
      this.equipmentImages = item.images;
      this.$bvModal.show("galleryModal");
    },
    deleteE(item) {
      this.deleteId = item.id;
      this.delete.id = this.deleteId;
      this.$bvModal.show("deleteEquipment");
    },
    deleteEquipment() {
      this.deleting = true;
      this.delete
        .delete(`equipment/${this.deleteId}`)
        .then(() => {
          this.currentPage = 1;
          this.getAllEquipment();
          this.$toast.success(i18n.tc("notifications.success"));
          this.closeModal();
          this.deleting = false;
        })
        .catch((e) => {
          this.$toast.warning(e.response.data.message);
          this.closeModal();
          this.deleting = false;
        });
    },
    unrent(id) {
      this.loading = true;
      API_URL.delete(`unrent-equipment/${id}`).then(() => {
        this.$toast.success(i18n.tc("notifications.success"));
        this.loading = false;
        this.getEquipmentDocuments();
      });
    },
    closeImport() {
      this.form_import.file = null;
      this.fileRecordImport = null;
    },
    closeModal() {
      this.form.reset();
      this.form.warranty = "";
      this.warranty = null;
      this.form.purchased_at = "";
      this.purchased_at = null;
      this.rent_form.reset();
      this.form.errors.errors = {};
      this.$bvModal.hide("deleteEquipment");
      this.deleteId = null;
      this.item = null;
      this.modalTitle = i18n.tc("equipment.add_new_equipment");
      this.selectedCategory = null;
      this.selectedSubcategory = null;
      this.selectedEquipmentType = null;
      this.selectedUser = null;
      this.equipmentRent = [];
      this.fileRecords = [];
    },
    async importExcel() {
      await this.form_import
        .post("equipment-import")
        .then(() => {
          this.getAllEquipment();
          this.$toast.success(i18n.tc("notifications.success"));
          this.$bvModal.hide("modal-import");
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            const errorMessage = error.response.data.error;
            Vue.prototype.$toast.error(errorMessage);
          }
        });
    },
    submitForm() {
      if (this.item === null) {
        this.form
          .post("equipment")
          .then(() => {
            this.getAllEquipment();
            this.$toast.success(i18n.tc("notifications.success"));
            this.$bvModal.hide("modal-1");
          })
          .catch(() => {
            // console.log("ERROR", error.data);
          });
      } else {
        this.form._method = "PUT";
        this.form
          .post(`equipment/${this.item.id}`)
          .then(() => {
            this.getAllEquipment();
            this.$toast.success(i18n.tc("notifications.success"));
            this.$bvModal.hide("modal-1");
          })
          .catch(() => {
            // console.log("ERROR", error.data);
          });
      }
    },
    getAllCategories() {
      API_URL.get("category").then((response) => {
        this.equipmentCategories = response.data.data;
      });
    },
    getEquipmentStatuses() {
      API_URL.get("equipment-statuses").then((response) => {
        this.equipmentStatuses = response.data;
      });
    },
    getAllUsers() {
      API_URL.get("users/all").then((response) => {
        this.users = response.data;
      });
    },
    getAllEquipment() {
      this.loading = true;
      API_URL.get("equipment", {
        params: {
          page: this.currentPage,
          ...this.filters,
        },
      }).then((response) => {
        this.equipment = response.data;
        this.loading = false;
      });
    },
    fileRemoved(id) {
      this.item.files = this.item.files.filter((f) => {
        return f.id !== id;
      });
    },
  },
  computed: {
    ...mapGetters({
      exporting: "exporting",
    }),
    categoriesOptions() {
      return [{ id: 0, name: "Sve kategorije" }, ...this.equipmentCategories];
    },
    subcategoriesOptionss() {
      return [
        { id: 0, name: "Sve potkategorije" },
        ...this.equipmentCategories.filter((item) => {
          if (item.id === this.filters.category) {
            return item.subcategories;
          }
        }),
      ];
    },
    resultsCount() {
      return this.equipment?.data?.length;
    },
  },
  watch: {
    filters: {
      deep: true,
      immediate: false,
      // eslint-disable-next-line no-unused-vars
      handler: debounce(function (value) {
        this.$withoutWatchers(() => (this.currentPage = 1));
        this.getAllEquipment();
      }, 250),
    },
    currentPage() {
      this.getAllEquipment();
    },
  },
  mounted() {
    this.getAllEquipment();
    this.getAllCategories();
    this.getEquipmentStatuses();
    this.getAllUsers();
    this.$emit("loadBreadcrumbLink", {
      pageName: "Oprema",
    });
  },
};
